import { useState } from 'react';

import { IDidomiObject } from '@didomi/react';

import { PURPOSES, VENDORS } from '../types/didomi';

const useConsentDidomi = () => {
  const initialState = {
    functionalConsent: false,
    raygunConsent: false,
    convertConsent: false,
  };

  const [consent, setConsent] = useState(initialState);

  const purposesRequiredForFunctionalConsent = [PURPOSES.FUNCTIONAL];

  const consentChecker = (
    purposesAvailable: string[],
    purposesRequiredForConsent: string[],
  ) =>
    purposesRequiredForConsent.every((purposeRequiredForConsent: string) =>
      purposesAvailable.includes(purposeRequiredForConsent),
    );

  const handleConsentUpdate = (purposes: string[]) => {
    const functionalConsent = consentChecker(
      purposes,
      purposesRequiredForFunctionalConsent,
    );
    const raygunConsent = !!checkVendorConsentStatus(VENDORS.RAYGUN);
    const convertConsent = !!checkVendorConsentStatus(VENDORS.CONVERT);
    setConsent({
      functionalConsent,
      raygunConsent,
      convertConsent,
    });
  };

  const checkVendorConsentStatus = (vendorId: string) => {
    if (typeof window !== 'undefined') {
      const consent = (window as any).Didomi.getUserConsentStatusForVendor(
        vendorId,
      );
      return consent;
    } else {
      return false;
    }
  };

  const onReady = (Didomi: IDidomiObject) => {
    const enabledPurposes =
      //@ts-ignore // Didomi type is missing "global" property on "purposes"
      Didomi.getUserStatus().purposes.global.enabled;
    handleConsentUpdate(
      enabledPurposes.map((purpose: any) => purpose.toString()),
    );
  };

  const onConsentChanged = () => {
    if (typeof (window as any).Didomi !== 'undefined') {
      const enabledPurposes = (window as any).Didomi.getUserStatus();
      //@ts-ignore // Didomi type is missing "global" property on "purposes"
      handleConsentUpdate(enabledPurposes.purposes.global.enabled);
    }
  };

  const DidomiConfig = {
    app: {
      user: {
        bots: {
          /**
           * Indicate whether consent is required for bots
           * Defaults to true
           */
          consentRequired: false,
        },
      },
    },
    integrations: {
      refreshOnConsent: true, // by default, ads are reloaded after consent is given
      vendors: {
        gcm: {
          enable: true, //enable Google Consent Mode
        },
      },
    },
  };

  return {
    DidomiConfig,
    onReady,
    onConsentChanged,
    allowRaygun: consent.raygunConsent,
    allowConvert: consent.convertConsent,
    consentChecker,
  };
};

export default useConsentDidomi;
