declare global {
  interface Window {
    rg4js: (action: string, event: RaygunEvent) => void;
  }
}

type RaygunEvent = {
  type: string;
  path: string;
};

const rg4js = (action: string, event: RaygunEvent) => {
  const { type, path } = event;
  if (window.rg4js) {
    window.rg4js(action, {
      type,
      path,
    });
  } else {
    console.error('rg4js is not available in the window object.');
  }
};

export { rg4js };
